exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-books-js": () => import("./../../../src/pages/books.js" /* webpackChunkName: "component---src-pages-books-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-oldnews-js": () => import("./../../../src/pages/oldnews.js" /* webpackChunkName: "component---src-pages-oldnews-js" */),
  "component---src-pages-people-js": () => import("./../../../src/pages/people.js" /* webpackChunkName: "component---src-pages-people-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-book-post-js": () => import("./../../../src/templates/book-post.js" /* webpackChunkName: "component---src-templates-book-post-js" */),
  "component---src-templates-book-post-read-js": () => import("./../../../src/templates/book-post-read.js" /* webpackChunkName: "component---src-templates-book-post-read-js" */),
  "component---src-templates-genre-post-js": () => import("./../../../src/templates/genre-post.js" /* webpackChunkName: "component---src-templates-genre-post-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-studio-js": () => import("./../../../src/templates/studio.js" /* webpackChunkName: "component---src-templates-studio-js" */),
  "component---src-templates-theme-post-js": () => import("./../../../src/templates/theme-post.js" /* webpackChunkName: "component---src-templates-theme-post-js" */),
  "component---src-templates-time-period-post-js": () => import("./../../../src/templates/time-period-post.js" /* webpackChunkName: "component---src-templates-time-period-post-js" */)
}

